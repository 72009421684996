<template>
  <div class="mt-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <div>
        <span class="is-pulled-right">
          <a class="button mr-2" @click="exportThisPage">Export</a>
        </span>

        <h1 class="title">Forecast</h1>

        <div class="mt-3 my-overflow">
          <div v-if="waiting">
            <span class="icon is-medium is-size-4">
              <i class="fas fa-spinner fa-pulse"></i>
            </span>
          </div>
          <div v-else>
            <div class="columns mt-4">
              <div class="column field mb-0 pb-0">
                <p class="control has-icons-left">
                  <span class="select">
                    <select v-model="filter">
                      <option v-for="(c, i) in classOptions"  :key="'product-class-option' + i">
                        {{c}}
                      </option>
                    </select>
                  </span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-filter"></i>
                  </span>
                </p>
              </div>
              <div class="column field  mb-0 pb-0">
                <p class="control has-icons-left">
                  <input class="input" type="text" placeholder="Search" v-model="search">
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </p>
              </div>
            </div>

            <div class="field is-grouped">
              <p class="control">
                <span class="select">
                  <select v-model="periods">
                    <option v-bind:value="2">Ship in 90 days</option>
                    <option v-bind:value="3">Ship in 135 days</option>
                    <option v-bind:value="4">Ship in 180 days</option>
                    <option v-bind:value="5">Ship in 225 days</option>
                    <option v-bind:value="6">Ship in 270 days</option>
                    <option v-bind:value="7">Ship in 315 days</option>
                    <option v-bind:value="8">Ship in 360 days</option>
                  </select>
                </span>
              </p>
            </div>

            <div class="table-container">
            <table class="table is-fullwidth is-hoverable is-striped">
              <thead>
                <th class="is-clickable" @click="changeSortOption('idIndex')">
                  <span>Id Index</span>
                  <span class="icon" v-if="sortOption.field == 'idIndex'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Id</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('nsName')">
                  <span>Product</span>
                  <span class="icon" v-if="sortOption.field == 'nsName'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Class</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('saleSpeed.value')">
                  <span>Sale Speed</span>
                  <span class="icon" v-if="sortOption.field == 'saleSpeed.value'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('inventory.US')">
                  <span>Inventory</span>
                  <span class="icon" v-if="sortOption.field == 'inventory.US'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">CA, NY, TX</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('daysToSell.inventory.days')">
                  <span>Days to sell inventory</span>
                  <span class="icon" v-if="sortOption.field == 'daysToSell.inventory.days'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('inbounds.US')">
                  <span>Inbound</span>
                  <span class="icon" v-if="sortOption.field == 'inbounds.US'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">CA, NY, TX</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('daysToSell.inventoryInbounds.days')">
                  <span>Days to sell inventory + inbound</span>
                  <span class="icon" v-if="sortOption.field == 'daysToSell.inventoryInbounds.days'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('openPos.US')">
                  <span>Open POs</span>
                  <span class="icon" v-if="sortOption.field == 'openPos.US'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">CA, NY, TX</span>
                </th>
                <th class="is-clickable" v-for="(h, j) in shipHeaders" :key="'ship-header-' + j" @click="changeSortOption(h.field)">
                  <span>{{h.name}}</span>
                  <span class="icon" v-if="sortOption.field == h.field">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">{{h.date}}</span>
                </th>
                <th class="is-clickable" v-for="(h, j) in shipHeaders" :key="'increase-pos-header-' + j" @click="changeSortOption(h.field1)">
                  <span>{{h.name1}}</span>
                  <span class="icon" v-if="sortOption.field == h.field1">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">{{h.date}}</span>
                </th>
              </thead>
              <tbody>
                <tr v-for="(p, i) in showingProducts" :key="'product-' + i">
                  <td>
                    <span>{{p.idIndex}}</span><br/>
                    <span class="is-size-7 has-text-grey">{{p.id}}</span>
                  </td>
                  <td>
                    <a @click="openForecastParamsModal(p)">{{p.nsName}}</a><br/>
                    <span class="is-size-7 has-text-grey">{{p.className}}</span>
                  </td>
                  <td>
                    <span>{{p.saleSpeed.label}}</span>
                  </td>
                  <td>
                    <span>{{p.inventory.US}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.inventory.CA + ', ' + p.inventory.NY + ', ' + p.inventory.TX}}</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="p.daysToSell.inventory.days != undefined">{{p.daysToSell.inventory.days}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.daysToSell.inventory.date}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.inbounds.US}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.inbounds.CA + ', ' + p.inbounds.NY + ', ' + p.inbounds.TX}}</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="p.daysToSell.inventoryInbounds.days != undefined">{{p.daysToSell.inventoryInbounds.days}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.daysToSell.inventoryInbounds.date}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.openPos.US}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.openPos.CA + ', ' + p.openPos.NY + ', ' + p.openPos.TX}}</span>
                    </span>
                  </td>
                  <td v-for="(h, j) in shipHeaders" :key="'ship-value-' + i + '-' + j">
                    <span v-if="p.ships[j] != undefined">{{p.ships[j]}}</span>
                  </td>
                  <td v-for="(h, j) in shipHeaders" :key="'increase-pos-value-' + i + '-' + j">
                    <span v-if="p.increasePos[j] != undefined">{{p.increasePos[j]}}</span><br/>
                    <span v-if="p.increasePosLocations[j] != undefined" class="is-size-7 has-text-grey">
                      <span>{{p.increasePosLocations[j].CA + ', ' + p.increasePosLocations[j].NY + ', ' + p.increasePosLocations[j].TX}}</span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
      
      
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
    </div>

    <forecast-params-modal :opened="forecastParamsModal.opened" :product="forecastParamsModal.product"
      @closeForecastParamsModal="closeForecastParamsModal">
    </forecast-params-modal>
  </div>
</template>

<script>
import dateFormat from 'dateformat'
import ForecastParamsModal from '@/components/modals/ForecastParamsModal'
import { saveAs } from 'file-saver'

function getFieldValue (obj, path) {

  var ss = path.split('.')
  var value = obj[ss[0]]
  var i = 1
  while (i < ss.length && value) {
    value = value[ss[i]]
    i += 1
  }
  return value
}

export default {
  name: 'Forecast',
  components: {
    ForecastParamsModal
  },
  data () {
    return {
      error: '',
      waiting: false,
      search: '',
      filter: 'All',
      products: [],
      classOptions: [],
      search: '',
      sortOption: { field: 'id', asc: true },
      periods: 5,
      forecastParamsModal: {
        opened: false,
        product: null,
      },
      shippingDays: {},
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    shipHeaders () {
      var headers = []
      var period = 1
      var now = new Date()
      while(period <= this.periods) {
        var days = 45 * period
        var date = new Date(now.getTime() + (days * 86400000))
        headers.push({
          name: 'Ship in ' + days + ' days',
          date: dateFormat(date, 'yyyy-mm-dd'),
          field: 'ships.' + (period - 1),
          name1: 'increase POs ship-in-' + days + '-days',
          field1: 'increasePos.' + (period - 1),
        })
        period += 1
      }
      return headers
    },
    showingProducts () {
      var vm = this
      var filteredProducts = this.products.filter(p => {
        if (vm.filter == 'All') {
          return true
        }
        return p.className == vm.filter
      }).filter(p => {
        var search = vm.search.toLowerCase()
        return p.id.toString().toLowerCase().includes(search)
          || p.model.toLowerCase().includes(search)
          || p.nsName.toLowerCase().includes(search)
          || p.className.toLowerCase().includes(search)
          || p.idIndex.toLowerCase().includes(search)
      })

      var computedProducts = filteredProducts.map(p => vm.computeProduct(p))

      var sort = vm.sortOption
      var sortedProducts = computedProducts.sort((a, b) => {
        var va = getFieldValue(a, sort.field)
        var vb = getFieldValue(b, sort.field)
        if (!va || !vb) {
          if (va) {
            return sort.asc ? 1 : -1
          }
          if (vb) {
            return sort.asc ? -1 : 1
          }
          return 0
        }
        if (sort.field == 'id' || sort.field == 'inventory.US' || sort.field == 'saleSpeed.value'
          || sort.field == 'inbounds.US'|| sort.field == 'openPos.US' || sort.field.startsWith('ships.')
          || sort.field.startsWith('increasePos.') || sort.field.startsWith('daysToSell.')) {
          return sort.asc ? va - vb : vb - va
        }
        return sort.asc ? va.localeCompare(vb) : vb.localeCompare(va)
      })
      return sortedProducts
    },
  },
  watch: {
    filter: function (val) {
    },
  },
  methods: {
    getProducts () {
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-products-for-forecast/').then(resp => {
        this.products = resp.body.products.map(p => this.makeProduct(p, resp.body.shippingDays))
        this.shippingDays = resp.body.shippingDays
        var classNames = new Set()
        this.products.forEach(p => classNames.add(p.className))
        this.classOptions = [...classNames]
        this.classOptions.sort((a, b) => a.localeCompare(b))
        this.classOptions.unshift('All')
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    makeProduct (p, shippingDays) {
      return {
        id: p.product.id,
        model: p.product.model,
        nsName: p.product.nsName,
        className: p.product.productClass,
        idIndex: p.product.nsIdIndex,
        inventory: this.makeInventory(p.inventory),
        saleSpeed: this.getSaleSpeed(p.product),
        inbounds: this.makeInbounds(p.inventory),
        openPos: this.makeOpenPos(p.inventory),
        forecastParams: this.getForecastParams(p.product, shippingDays),
      }
    },
    makeInventory (inventory) {
      var caInventory = inventory.caInventory || 0
      var nyInventory = inventory.nyInventory || 0
      var txInventory = inventory.txInventory || 0
      var inventory = caInventory + nyInventory + txInventory
      return {
        US: inventory,
        CA: caInventory,
        NY: nyInventory,
        TX: txInventory,
      }
    },
    getSaleSpeed (p) {
      var salesData = p.salesData ? JSON.parse(p.salesData) : {}
      var saleSpeed = salesData.saleSpeed || 0
      var saleSpeedLabel = saleSpeed ? this.roundNum(saleSpeed) : '0'
      return {value: saleSpeed, label: saleSpeedLabel}
    },
    makeInbounds (inventory) {
      var inbounds = inventory.inbounds ? JSON.parse(inventory.inbounds) : {}
      inbounds.CA = inbounds.CA ? parseInt(inbounds.CA) : 0
      inbounds.NY = inbounds.NY ? parseInt(inbounds.NY) : 0
      inbounds.TX = inbounds.TX ? parseInt(inbounds.TX) : 0
      var usInbounds = inbounds.CA + inbounds.NY + inbounds.TX
      inbounds.US = usInbounds
      return inbounds
    },
    makeOpenPos (inventory) {
      var openPos = inventory.openPos ? JSON.parse(inventory.openPos) : {}
      openPos.CA = openPos.CA ? parseInt(openPos.CA) : 0
      openPos.NY = openPos.NY ? parseInt(openPos.NY) : 0
      openPos.TX = openPos.TX ? parseInt(openPos.TX) : 0
      var usOpenPos = openPos.CA + openPos.NY + openPos.TX
      openPos.US = usOpenPos
      return openPos
    },
    getForecastParams (p, shippingDays) {
      var forecastParams = p.forecastParams ? JSON.parse(p.forecastParams) : {}
      if (!forecastParams.daysToStock) {
        forecastParams.daysToStock = 45
      }
      if (!forecastParams.factoryPrepDays) {
        forecastParams.factoryPrepDays = 45
      }
      if (shippingDays) {
        forecastParams.shippingDays = shippingDays.US
      }
      if (!forecastParams.shippingDays) {
        forecastParams.shippingDays = 45
      }
      if (!forecastParams.optimizedLocationRatio) {
        forecastParams.optimizedLocationRatio = {CA: 26, NY: 31, TX: 43}
      }
      return forecastParams
    },
    computeProduct (p) {
      var daysToSell = this.computeDayToSell(p)
      var ships = this.computeShips(p)
      var increasePos = this.computeIncreasePos(p, ships)
      var increasePosLocations = this.computeIncreasePosLocations(p, increasePos)
      return {...p, daysToSell: daysToSell, ships: ships, increasePos: increasePos, increasePosLocations: increasePosLocations}
    },
    computeDayToSell (p) {
      var daysToSell = {inventory: {}, inventoryInbounds: {}}
      var speed = p.saleSpeed.value
      if (!speed) {
        return daysToSell
      }
      var inventory = p.inventory.US
      var inbounds = p.inbounds.US
      var now = new Date()
      var daysToSellInventoryDays = Math.round(inventory / speed)
      var daysToSellInventoryDate = new Date(now.getTime() + (daysToSellInventoryDays * 86400000))
      daysToSell['inventory'] = {days: daysToSellInventoryDays, date: dateFormat(daysToSellInventoryDate, 'yyyy-mm-dd')}
      var daysToSellInventoryInboundsDays = Math.round((inventory + inbounds) / speed)
      var daysToSellInventoryInboundsDate = new Date(now.getTime() + (daysToSellInventoryInboundsDays * 86400000))
      daysToSell['inventoryInbounds'] = {days: daysToSellInventoryInboundsDays, date: dateFormat(daysToSellInventoryInboundsDate, 'yyyy-mm-dd')}
      return daysToSell
    },
    computeShips (p) {
      var ships = []
      var period = 1
      var sum = 0
      var speed = p.saleSpeed.value
      var shippingDays = p.forecastParams.shippingDays
      var daysToStock = p.forecastParams.daysToStock
      var factoryPrepDays = p.forecastParams.factoryPrepDays
      var inventory = p.inventory.US
      var inbounds = p.inbounds.US
      while (period <= this.periods) {
        var v1 = inventory + inbounds + sum - (speed * shippingDays * period)
        var m1 = Math.max(v1, 0)
        var v2 = speed * (daysToStock + factoryPrepDays) - m1
        var m2 = Math.max(v2, 0)
        ships.push(Math.round(m2))
        sum += m2
        period += 1
      }
      return ships
    },
    computeIncreasePos (p, ships) {
      var openPos = p.openPos.US
      var sum = 0
      var increasePos = []
      for (var ship of ships) {
        var m1 = Math.max(openPos - sum, 0)
        var m2 = Math.max(ship - m1, 0)
        increasePos.push(Math.round(m2))
        sum += ship
      }
      return increasePos
    },
    computeIncreasePosLocations (p, increasePos) {
      var allLocations = ['CA', 'NY', 'TX']
      var ratio = this.computeRatio(p.forecastParams.optimizedLocationRatio, allLocations)
      var inventory = {CA: p.inventory.CA, NY: p.inventory.NY, TX: p.inventory.TX}
      var inbounds = {CA: p.inbounds.CA, NY: p.inbounds.NY, TX: p.inbounds.TX}
      var openPos = {CA: p.openPos.CA, NY: p.openPos.NY, TX: p.openPos.TX}
      var quantities = {
        CA: inventory.CA + inbounds.CA + openPos.CA,
        NY: inventory.NY + inbounds.NY + openPos.NY,
        TX: inventory.TX + inbounds.TX + openPos.TX,
      }
      var increasePosLocations = []
      for (var pos of increasePos) {
        var increases = {CA: 0, NY: 0, TX: 0}
        if (!pos) {
          increasePosLocations.push(increases)
          continue
        }
        var oldLocs = allLocations.slice()
        var newLocs = []
        while (true) {
          var target = this.computeTarget(quantities, pos, ratio, oldLocs)
          var newLocs = []
          for (var loc of oldLocs) {
            if (target[loc] - quantities[loc] >= 1) {
              newLocs.push(loc)
            }
          }
          if (newLocs.length == 1 || newLocs.length == oldLocs.length) {
            break
          }
          oldLocs = newLocs
        }

        var target = this.computeTarget(quantities, pos, ratio, newLocs)
        var remaining = pos
        for (var i=0;i<newLocs.length;i++) {
          var loc = newLocs[i]
          if (i == newLocs.length - 1) {
            increases[loc] = remaining
          } else {
            var increase = Math.floor(target[loc]) - quantities[loc]
            increases[loc] = increase
            remaining -= increase
          }
        }

        increasePosLocations.push(increases)
        for (var loc of allLocations) {
          quantities[loc] += increases[loc]
        }
      }
      return increasePosLocations
    },
    computeTarget (quantities, pos, ratio, locs) {
      var total = pos
      for (var loc of locs) {
        total += quantities[loc]
      }
      var target = {}
      for (var loc of locs) {
        target[loc] = total * ratio[loc]
      }
      return target
    },
    computeRatio (ratio, keys) {
      var sum = 0
      for (var k of keys) {
        sum += ratio[k]
      }
      var result = {}
      for (var k of keys) {
        result[k] = ratio[k] / sum
      }
      return result
    },
    roundNum (n) {
      if (n >= 10) {
        return Math.round(n)
      }
      if (n >= 1) {
        return Math.round(n * 10) / 10
      }
      return Math.round(n * 100) / 100
    },
    changeSortOption (field) {
      this.sortOption = {
        field: field,
        asc: field == this.sortOption.field ? !this.sortOption.asc : true
      }
    },
    openForecastParamsModal (p) {
      this.forecastParamsModal = {
        opened: true,
        product: p,
      }
    },
    closeForecastParamsModal (e) {
      if (e) {
        var productId = this.forecastParamsModal.product.id
        for (var p of this.products) {
          if (p.id == productId) {
            p.forecastParams = {...e, shippingDays: this.shippingDays.US}
            break
          }
        }
      }
      this.forecastParamsModal = {
        opened: false,
        product: null,
      }
    },
    exportThisPage () {
      var headers = ['id', 'idIndex', 'nsName', 'className', 'saleSpeed.value', 
        'inventory.CA', 'inventory.NY', 'inventory.TX', 'inventory.US', 'daysToSell.inventory.days', 'daysToSell.inventory.date',
        'inbounds.CA', 'inbounds.NY', 'inbounds.TX', 'inbounds.US', 'daysToSell.inventoryInbounds.days', 'daysToSell.inventoryInbounds.date', 
        'openPos.CA', 'openPos.NY', 'openPos.TX', 'openPos.US',
        'forecastParams.daysToStock', 'forecastParams.factoryPrepDays', 'forecastParams.shippingDays',
        'forecastParams.optimizedLocationRatio.CA', 'forecastParams.optimizedLocationRatio.NY', 'forecastParams.optimizedLocationRatio.TX',
      ]
      for (var h of this.shipHeaders) {
        headers.push({label: h.name + ': ' + h.date, value: h.field})
      }
      for (var i=0;i<this.shipHeaders.length;i++) {
        var h = this.shipHeaders[i]
        headers.push({label: h.name1 + ': ' + h.date, value: h.field1})
        for (var loc of ['CA', 'NY', 'TX']) {
          headers.push({label: h.name1 + '.' + loc, value: 'increasePosLocations.' + i + '.' + loc})
        }
      }
      var rows = this.showingProducts.map(p => headers.map (h => (h.value ? (getFieldValue(p, h.value) ? getFieldValue(p, h.value) : '') : (getFieldValue(p, h) ? getFieldValue(p, h) : ''))).join('\t'))
      rows.unshift(headers.map(h => h.label ? h.label : h).join('\t'))
      var string = rows.join('\r\n')
      var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
      var today = dateFormat(new Date(), 'isoDate')
      saveAs(blob, 'forecast_' + today + '.tsv')
    },
  },
  mounted () {
    this.getProducts()
  },
}
</script>

<style scoped>
.my-overflow {
  overflow-x: scroll;
}

.table-container {
  max-height: calc(100vh - 80px);
  overflow: auto;

  table {
    text-align: left;
    position: relative;
  }

  th {
    background: white;
    position: sticky;
    top: 0;
  }
}
</style>
  